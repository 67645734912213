<template>
  <div class="verify">
    <span v-if="verified === null">Please wait...</span>
    <span v-else-if="verified">You have been verified! Redirecting...</span>
    <span v-else>There was a problem verifying your account. Please try again.</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ApiService } from '@/services/api.service';

export default {
  name: 'Verify',
  components: {},
  data() {
    return {
      verified: null,
    };
  },
  mounted() {
    this.verify();
  },
  methods: {
    ...mapActions(['parseJwt']),

    async verify() {
      try {
        const { token } = (await ApiService.post('/user/verify', { token: this.$route.query.token })).data;
        this.parseJwt(token);
        this.verified = true;
        setTimeout(() => {
          if (this.$route.query.next) this.$router.push(this.$route.query.next);
          else this.$router.push('/home');
        }, 1000);
      } catch (error) {
        this.verified = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.verify {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  margin-top: 8em;
  font-size: 1.2rem;
}
</style>
